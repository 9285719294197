export default class TypewriterAnimation {
    constructor(options) {
        const {
            element,
            items,
            delay
        } = options
        
        this.element = element || '.typewriter'
        this.items = items || []
        this.delay = parseInt(delay, 10) || 2000
        this.currentItem = 0
        this.text = ''
        this.doTyping()
        this.isCurrentDeleting = false
    }

    doTyping() {
        const index = this.currentItem % this.items.length;
        const fullText = this.items[index];

        if (this.isCurrentDeleting) {
            this.text = fullText.substring(0, this.text.length - 1);
        } else {
            this.text = fullText.substring(0, this.text.length + 1);
        }

        this.element.innerHTML = '<span class="wrap">' + this.text + '</span>';

        let delta = 200 - Math.random() * 100;

        if (this.isCurrentDeleting) { delta /= 2; }

        if (!this.isCurrentDeleting && this.text === fullText) {
            delta = this.delay;
            this.isCurrentDeleting = true;
        } else if (this.isCurrentDeleting && this.text === '') {
            this.isCurrentDeleting = false;
            this.currentItem++;
            delta = 500;
        }

        setTimeout(() => this.doTyping(), delta);
    }

    static init() {
        const elements = document.getElementsByClassName('typewrite')
        for (let index = 0; index < elements.length; index++) {
            const items = elements[index].getAttribute('data-type')
            const delay = elements[index].getAttribute('data-period')
            if (items) {
                new TypewriterAnimation({
                    element: elements[index],
                    items: items.split("|"),
                    delay
                })
            }
        }
    }
}